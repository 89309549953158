<template>
  <v-app id="restore">
    <v-main>
      <v-container fluid fill-height>
        <v-row align="center" justify="center">
          <v-col cols="12" md="5">
            <v-img :src="logo" max-height="250" contain/>
          </v-col>
          <v-col cols="2" class="d-none d-md-flex">
            <div class="vl"></div>
          </v-col>
          <v-col cols="12" md="5">
            <template>
              <v-card class="mx-auto" elevation="0" max-width="500">
                <v-card-title class="justify-center mb-3 main-title">
                  Restablecer contraseña
                </v-card-title>
                <v-card-title class="justify-center mb-3 sub-title">
                  Ingresa la contraseña
                </v-card-title>
                <v-card-text>
                  <v-form ref="restoreForm" lazy-validation @submit.prevent>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field v-model="forms.restore.password" type="password" label="Contraseña" :rules="rules.password" :disabled="loading" outlined required autocomplete="off"></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field v-model="forms.restore.confirmPassword" type="password" label="Confirmar contraseña" :rules="rules.confirmPassword" :disabled="loading" outlined required autocomplete="off"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                  <v-row align="center" justify="space-around" class="pa-3">
                    <v-btn large block color="primary" class="text-normal" :loading="loading" @click="restore()">Enviar solicitud</v-btn>
                  </v-row>
                </v-card-text>
              </v-card>
            </template>
          </v-col>
        </v-row>
        <v-snackbar v-model="error" color="error" :timeout="2500" bottom right>
          {{ msgError }}
        </v-snackbar>
        <v-snackbar v-model="success" color="success" :timeout="2500" bottom right>
          {{ msgSuccess }}
        </v-snackbar>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'restore',
  data: () => ({
    host: process.env.VUE_APP_API_SERVER,
    name: process.env.VUE_APP_NAME,
    success: false,
    msgSuccess: '',
    logo: require('@/assets/logo.png'),
    paths: {
      restore: '/pub/user/set/password',
      isValid: '/pub/user/is/valid/code'
    },
    loading: false, 
    error: false,
    msgError: '',
    state: 1,
    forms: {
      restore: {
        password: '',
        confirmPassword: ''
      },
    },
    rules: {}
  }),
  created() {
    this.rules = {
      password: [
        v => !!v || '',
        v => (v && v.length <= 60) || '',
        v => /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(v) || 
        'La contraseña debe tener al menos una letra mayúscula, una letra minúscula, un número y minimo 8 caracteres',
      ],
      confirmPassword: [
        v => !!v || '',
        v => (v && v.length <= 60) || '',
         v => v === this.forms.restore.password || 'Las contraseñas no coinciden'
      ]
    }
  },
  mounted() {
    this.isValid()
  },
  methods: {
    restore() {
      if (this.$refs.restoreForm.validate()) {
        this.loading = true;
        let data = {
          password: this.forms.restore.password,
          code: this.$route.params.hash,
        }
        this.axios.post(this.host + this.paths.restore, { data })
          .then(response => {
            this.success = true
            this.msgSuccess = "Contraseña actualizada correctamente"
          })
          .catch(error => {})
          .finally(() => {
            setTimeout(() => {
              this.$router.push('/login');
            }, 2000); 
            this.loading = false
          })
      } else {
        this.error = true
        this.msgError = 'La información es incorrecta'
      }
    },
    isValid() {
      this.loading = true;
      let data = {
        password: this.forms.restore.password,
        code: this.$route.params.hash,
      }
      this.axios.post(this.host + this.paths.isValid, { data })
        .then(response => {
        
        })
        .catch(error => {
          this.error = true
          this.msgError = error.response.data.message ?? 'La información es incorrecta'

          setTimeout(() => {
            this.$router.push('/login');
          }, 2000); // Redirigir después de 2 segundos (2000 milisegundos)
        })
        .finally(() => {
          this.loading = false
        })
  }
  }
}
</script>

<style scoped>
  .vl {
    margin-left: 50px;
    border-left: 1px solid black;
    height: 300px;
  }
  .main-title {
    font-size: 38px;
    font-weight: bold;
    color: black;
  }
  .sub-title {
    font-size: 18px;
    color: black;
  }
  .text-normal {
    text-transform: none !important;
  }
</style>
